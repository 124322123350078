import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ShowTrips from "./pages/ShowTrips";
import ShowTrip from "./pages/ShowTrip";
import ShowPlace from "./pages/ShowPlace";

function App() {
  var Airtable = require("airtable");

  try {
    var base = new Airtable({ apiKey: process.env.REACT_APP_API_READ }).base(
      "appNrh5iyUNaEPu4k"
    );
  } catch (e) {
    console.log("Airtable API key is not defined", e);
  }

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/trips" element={<ShowTrips base={base} />} />
        <Route path="/trips/:tripId" element={<ShowTrip base={base} />} />
        <Route
          path="/trips/places/:place"
          element={<ShowPlace base={base} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
