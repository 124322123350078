import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CityCard from "../components/CityCard";

const ShowTrip = ({ base }) => {
  const { tripId } = useParams();
  const [cityInfo, setCityInfo] = useState([]);

  const retreiveTripInfos = async (tripId) => {
    try {
      var tab = [];
      await base("french_cities")
        .select({
          maxRecords: 20,
          view: "Grid view",
        })
        .eachPage(function page(records, fetchNextPage) {
          records.forEach(function (record) {
            if (record.fields.City === tripId) {
              tab.push(record.fields);
            }
          });
          fetchNextPage();
        });
      setCityInfo(tab);
    } catch (e) {
      console.log("Airtable API key is not defined", e);
    }
  };

  useEffect(() => {
    retreiveTripInfos(tripId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  if (!cityInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="main">
      <h1>{tripId}</h1>
      {cityInfo.length === 0 ? (
        <h1>
          <i className="fa fa-spinner fa-spin"></i>
        </h1>
      ) : (
        <>
          <div className="cards-container">
            {cityInfo.map((trip, index) => (
              <CityCard
                key={index}
                title={trip.Title}
                image={trip.Image !== undefined ? trip.Image[0].url : null}
                link={trip.Link}
                address={trip.Address}
              />
            ))}
          </div>
          <Link to="/trips" className="link-container">
            <i className="fa fa-arrow-left"></i>
          </Link>
        </>
      )}
    </div>
  );
};

export default ShowTrip;
