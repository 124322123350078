import React from "react";
import { Link } from "react-router-dom";

const Card = ({ title }) => {
  return (
    <div className="card hover-main">
      <Link to={`/trips/${title}`}>
        <h2>{title}</h2>
      </Link>
    </div>
  );
};

export default Card;
