import React from "react";
import { Link } from "react-router-dom";
import placeholder from "../images/placeholder.png";

const Card = ({ title, image, link, address }) => {
  return (
    <div className="city-card">
      <h2>{title}</h2>
      <div className="city-card-links">
        <a href={link} target="_blank" rel="noreferrer">
          <i className="fa fa-link"></i>
        </a>
        <a href={address} target="_blank" rel="noreferrer">
          <i className="fa fa-map-marker-alt"></i>
        </a>
        <Link to={`/trips/places/${title.replaceAll(" ", "")}`}>
          <i className="fa fa-info-circle"></i>
        </Link>
      </div>
      <img
        src={image !== null ? image : placeholder} // https://via.placeholder.com/150
        alt={title}
      />
    </div>
  );
};

export default Card;
