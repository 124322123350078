import React, { useState, useEffect } from "react";
import Card from "../components/Card";

const ShowTrips = ({ base }) => {
  const [trips, setTrips] = useState([]);

  const retreiveTrips = async () => {
    try {
      var records = await base("french_cities")
        .select({
          maxRecords: 20,
          view: "Grid view",
        })
        .all();
      var tab = [];
      for (let i = 0; i < records.length; i++) {
        if (!tab.includes(records[i].fields.City)) {
          tab.push(records[i].fields.City);
        } else {
          console.log("City already in the list");
        }
      }
      setTrips(tab);
    } catch (e) {
      console.log("Airtable API key is not defined", e);
    }
  };

  useEffect(() => {
    retreiveTrips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h1>SnyTrips</h1>
      {trips.length === 0 ? (
        <div className="cards-container">
          <Card title="Loading..." />
        </div>
      ) : (
        <div className="cards-container">
          {trips.map((trip, index) => (
            <Card key={index} title={trip} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ShowTrips;
