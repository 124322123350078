import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import placeholder from "../images/placeholder.png";

const ShowPlace = ({ base }) => {
  const { place } = useParams();
  const [placeInfo, setPlaceInfo] = useState(null);

  const retreivePlaceInfos = async (place) => {
    try {
      await base("french_cities")
        .select({
          maxRecords: 20,
          view: "Grid view",
        })
        .eachPage(function page(records, fetchNextPage) {
          records.forEach(function (record) {
            if (record.fields.Title.replaceAll(" ", "") === place) {
              setPlaceInfo(record.fields);
              return;
            }
          });
          fetchNextPage();
        });
    } catch (e) {
      console.log("Airtable API key is not defined", e);
    }
  };

  useEffect(() => {
    retreivePlaceInfos(place);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  return (
    <div className="main place">
      {placeInfo === null ? (
        <h1>
          <i className="fa fa-spinner fa-spin"></i>
        </h1>
      ) : (
        <>
          <h1>{placeInfo.Title}</h1>
          <div className="city-card-links city-card-links-line">
            <a href={placeInfo.Link} target="_blank" rel="noreferrer">
              <i className="fa fa-link"></i>
              {placeInfo.Link}
            </a>
          </div>
          <div className="city-card-links city-card-links-line">
            <a href={placeInfo.Address} target="_blank" rel="noreferrer">
              <i className="fa fa-map-marker-alt"></i>
              {placeInfo.Address}
            </a>
          </div>
          <img
            src={
              placeInfo.Image !== undefined
                ? placeInfo.Image[0].url
                : placeholder
            }
            alt={placeInfo.Title}
            className="place-image"
          />
          <p>{placeInfo.Comment}</p>
          <Link to={`/trips/${placeInfo.City}`} className="link-container">
            <i className="fa fa-arrow-left"></i>
          </Link>
        </>
      )}
    </div>
  );
};

export default ShowPlace;
